import {H2} from "../Headlines";
import Section from "./Section";

type person = {
    name: string,
    href: string,
    imageUrl: string,
    bio: string,
}

const people: person[] = [
    {
        name: 'Tobias Michael-Weber',
        href: 'tobias_michael-weber',
        imageUrl: '/images/team/TobiasMichael-Weber.jpg',
        bio: '',
    },
    {
        name: 'Benedikt Oppermann',
        href: 'benedikt_oppermann',
        imageUrl: '/images/team/BenediktOppermann.jpg',
        bio: '',
    },
    {
        name: 'Richard Scholz',
        href: 'richard_scholz',
        imageUrl: '/images/team/RichardScholz.jpg',
        bio: '',
    },
    {
        name: 'Sara Kleespies',
        href: 'sara_kleespies',
        imageUrl: '/images/team/SaraKleespies.jpg',
        bio: '',
    },
    {
        name: 'Darren Röll',
        href: 'darren_roell',
        imageUrl: '/images/team/DarrenRoell.jpg',
        bio: '',
    },
]

export default function UnserTeam() {
    return <>
        <Section id={'unser-team'}>
            <H2 id={'unser-team-headline'} className={'mb-12'}>Unser Team</H2>
            <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
                {people.map((person) => (
                    <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row justify-center">
                        {person.imageUrl !== '' && <img alt="" src={person.imageUrl} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"/>}
                        <div className="max-w-xl flex-auto">
                            <h3 id={person.href} className="text-2xl font-semibold leading-8 tracking-tight text-green-600">{person.name}</h3>
                            <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </Section>
    </>
}